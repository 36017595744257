Revolution.Module.extend({
	NAME : "mde.main", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)
    initialize : function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        _.bindAll(this, "wrapAccordion");

        //Example to call an external function
        //var UTILS = Revolution.get("mde._utils");
        //UTILS.equalheight($('.bk-mosaique .js-equal-height'));

        // Init select2js (page recommendation)
        $('.js-custom-select-no-border').select2({
            minimumResultsForSearch: -1,
            width: '100%',
            theme: 'no-border'
        });

        $('.js-custom-select-border').each(function( index ) {
            $(this).select2({
                minimumResultsForSearch: -1,
                width: '100%',
                theme: 'border'
            });

            // if it is common select
            if ($(this).hasClass('js-select-common')) {
                $(this).on("select2:close", function(e) {
                    var value = $(this).select2('data')[0].text;
                    $('[data-name="'+$(this).data('target')+'"]').each(function(index, el) {
                        $(el).val(value);
                        $(el).trigger({
                            type: 'select2:select',
                            params: {
                                data: $(el).select2('data')[0]
                            }
                        });
                        $(el).trigger('change');
                    });
                });
            }
        });

        $('.js-custom-select-languages').select2({
            minimumResultsForSearch: -1,
            width: '100%',
            theme: 'languages'
        });

        $('.js-custom-select-border-bottom').each(function( index ) {
            var parent = $(this).parent();
            $(this).select2({
                minimumResultsForSearch: -1,
                width: 'auto',
                theme: 'border-bottom',
                dropdownParent: parent
            });
        });

        // disable scrolling  for input number
        $('input[type=number]').on('mousewheel',function(e){ e.preventDefault(); });

        window.accordion = $('.field-app_bundle_dimension_type, .field-app_bundle_ponderation_type, .field-app_bundle_ponderation_multi_type, .field-app_bundle_restriction_collection_type, .field-app_bundle_restriction_option_collection_type, .field-app_bundle_question_type');
        this.accordionAdmin();
        $('.field-app_bundle_dimension_type, .field-app_bundle_ponderation_type, .field-app_bundle_ponderation_multi_type, .field-app_bundle_restriction_collection_type, .field-app_bundle_restriction_option_collection_type, .field-app_bundle_question_type').find('> .control-label').click();

        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.attributeName === "class") {
                    if (!$('body').hasClass('modal-open') && $('.field-app_bundle_ponderation_type > .control-label:not(.is-closed)').length) {
                        $('.field-app_bundle_ponderation_type > .control-label:not(.is-closed)').next('.content-accordion').show()
                    }
                    if (!$('body').hasClass('modal-open') && $('.field-app_bundle_ponderation_multi_type > .control-label:not(.is-closed)').length) {
                        $('.field-app_bundle_ponderation_multi_type > .control-label:not(.is-closed)').next('.content-accordion').show()
                    }
                }
            });
        });
        $('body').each(function(index, el) {
            observer.observe($(el)[0], {
                attributes: true
            });
        });

        if ($('body').hasClass('mode1')) {
            $(document).ajaxStop(function() {
                $('.overall-total').closest('li').find('.loader-wrapper').removeClass('loading');
            });
        }
    },

    onWindowLoad : function (e) {

        // Backoffice duplication message.
        $('body').on('click', '.action-duplicate_pricing, .action-duplicate_version', function () {
            $('#modal-delete .modal-content').css({
                'width': '50%',
                'left': '25%',
            });
            $('#modal-delete .modal-body').html('<h4>Duplicating...</h4><p>Please wait for the page to reload before initiating any action.</p>');
            $('#modal-delete .modal-footer').remove();
            $('#modal-delete').modal();
        });
	},
	onWindowScroll : function (e) {
		//
	},
	onWindowResize : function (e) {
		//

	},

	accordionAdmin : function () {
        $('body').on('click', '.field-app_bundle_dimension_type > .control-label, .field-app_bundle_ponderation_type > .control-label, .field-app_bundle_ponderation_multi_type > .control-label, .field-app_bundle_restriction_collection_type > .control-label, .field-app_bundle_restriction_option_collection_type > .control-label, .field-app_bundle_question_type > .control-label', function () {
            var $content = $(this).next('.content-accordion');
            if($(this).hasClass('is-closed')) {
                window.accordion.find('> .control-label').addClass('is-closed');
                window.accordion.find('.content-accordion').slideUp();
                $(this).removeClass('is-closed');
                $content.slideDown();
            } else {
                $(this).addClass('is-closed');
                $content.slideUp();
            }
        });
    },

    wrapAccordion: function() {
        window.accordion.each(function(index, el) {
            if(!$(el).find('.content-accordion').length) {
                $(el).find('> *:not(.control-label:first-child)').wrapAll('<div class="content-accordion"></div>');
            }
        });
    }
});
