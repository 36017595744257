Revolution.Module.extend({
    NAME: "mde._utils", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)
    $menuMobile: $('.btn-nav-mob'),

    initialize: function ($) {
      // appelé aussitôt la page chargée
      //console.log("module " + this.NAME);

      //_.bindAll(this,"myFunction");

      //events
      $('body').on('click', 'a[href^="#"]:not([href="#"])', this.smoothScroll);

      $('body').on('blur', '.js-temp-focus', this.blurTempFocus);

      this.coverLike();

    },

    onWindowLoad: function (e) {
      //console.log("all assets loaded", e);

      this.coverLike();
    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {

      this.coverLike();
    },

    isMenuMobile: function () {
      THIS = this;
      if (THIS.$menuMobile.is(':visible')) {
        return true;
      } else {
        return false;
      }
    },
    isMenuDesktop: function () {
      THIS = this;
      if (THIS.$menuMobile.is(':visible')) {
        return false;
      } else {
        return true;
      }
    },


    smoothScroll: function (e) {
        $obj = $(e.currentTarget);
        // if (!$obj.hasClass('js-no-scrolling')) { // not work on .js-no-scrolling items
        //     var href = $obj.attr('href');
        //     // on scroll jusqu'à  l'ancre
        //     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        //         var target = $(this.hash);
        //         target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        //         //var adjust = $(".page-header-wrapper").height() + 50;
        //         var adjust = 0;
        //         // console.log('target.offset().top :'+target.offset().top);
        //         if (target.length) {
        //             $('html, body').animate({
        //                 scrollTop: target.offset().top - adjust
        //             }, 1000, function () {
        //                 window.location.hash = href;
        //             });
        //             return false;
        //         }
        //     }
        // }

    },

    giveTempFocusTo: function($target) {
      $target.addClass('js-temp-focus').attr('tabindex','-1').focus();
    },
    blurTempFocus: function (e) {
      $obj = $(e.currentTarget);
      $obj.removeClass('js-temp-focus').removeClass('anchor-new-content').removeAttr('tabindex');
    },

    coverLike : function (callback) {
      $('.cover').each(function(index, element) {
          $this = $(element);
          $this.css('max-width', 'inherit');
          $this.closest('.cover-parent').css('overflow', 'hidden');


          var largeurInitVisu = $this.data('w');
          var hauteurInitVisu = $this.data('h');


          if (largeurInitVisu == undefined || hauteurInitVisu == undefined) {
            $this.css({'width' : '', 'height': ''});
            largeurInitVisu = $this.width();
            hauteurInitVisu = $this.height();
          }

          var ratioVisu = largeurInitVisu/hauteurInitVisu;

          var largeurConteneur = $this.closest('.cover-parent').outerWidth();
          var hauteurConteneur = $this.closest('.cover-parent').outerHeight();

          var ratioConteneur = largeurConteneur/hauteurConteneur;


          if (ratioConteneur > ratioVisu) {
            $this.width(largeurConteneur);
            $this.height(largeurConteneur/ratioVisu);
            if ($this.hasClass('valign-t')) {
              //VAlignee au en haut
              var decalage = 0;
            } else if($this.hasClass('valign-b')) {
              //VAlignee au en bas
              var decalage = hauteurConteneur-$this.height();
            } else {
              //VAlignee au milieu
              var decalage = (hauteurConteneur-$this.height())/2;
            }

            $this.css('left', 0);
            $this.css('top',decalage);
          } else {
            $this.height(hauteurConteneur);
            $this.width(hauteurConteneur*ratioVisu);
            if ($this.hasClass('align-l')) {
              //Alignee au en haut
              var decalage = 0;
            } else if($this.hasClass('align-r')) {
              //Alignee au en bas
              var decalage = largeurConteneur-$this.width();
            } else {
              //Alignee au milieu
              var decalage = (largeurConteneur-$this.width())/2;
            }

            $this.css('top', 0);
            $this.css('left',decalage);
          }


          //CALLBACK
          if(callback) {
            //
          }
      });
    },

    containLike : function (callback) {
      $('.contain').each(function(index, element) {
          $this = $(element);
          $this.css({'max-width': '100%', 'max-height': '100%'});


          var largeurInitVisu = ($(this).attr('width')) ? $this.attr('width') : $this.data('w');
          var hauteurInitVisu = ($(this).attr('height')) ? $this.attr('height') : $this.data('h');

          var ratioVisu = largeurInitVisu/hauteurInitVisu;

          if (isNaN(ratioVisu)) {
            console.log('========================');
            console.log('YOU HAVE TO SET WIDTH AND HEIGHT or DATA-W AND DATA-H ATTRIBUTES ON THIS ELEMENT');
            console.log(element);
            console.log('========================');
          }
          if (largeurInitVisu == undefined || hauteurInitVisu == undefined) {
            largeurInitVisu = $this.width();
            hauteurInitVisu = $this.height();
            ratioVisu = largeurInitVisu/hauteurInitVisu;
          }

          var largeurConteneur = $this.closest('.contain-parent').outerWidth();
          var hauteurConteneur = $this.closest('.contain-parent').outerHeight();

          var ratioConteneur = largeurConteneur/hauteurConteneur;

          /*console.log('------------------------------');
          console.log('largeurConteneur : '+largeurConteneur);
          console.log('hauteurConteneur : '+hauteurConteneur);
          console.log('ratioConteneur : '+ratioConteneur);
          console.log('------------------------------');
          console.log('largeurInitVisu : '+largeurInitVisu);
          console.log('hauteurInitVisu : '+hauteurInitVisu);
          console.log('ratioVisu : '+ratioVisu);
          console.log('------------------------------');*/

          if (ratioConteneur > ratioVisu) { // Conteneur + Pano que visu
            $this.height(hauteurConteneur);
            $this.width(hauteurConteneur*ratioVisu);

            //Alignee au milieu
            var decalage = (largeurConteneur-$this.width())/2;

            /*$this.css('left', 0);
            $this.css('top',decalage);*/
            $this.css('top', 0);
            $this.css('left',decalage);
          } else { // Conteneur + Portrait que visu
            $this.width(largeurConteneur);
            $this.height(largeurConteneur/ratioVisu);

            //VAlignee au milieu
            var decalage = (hauteurConteneur-$this.height())/2;

            /*$this.css('top', 0);
            $this.css('left',decalage);*/
            $this.css('left', 0);
            $this.css('top',decalage);

          }
      });
    }

});
