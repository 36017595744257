Revolution.Module.extend({
	NAME : "mde.recommendations", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)
    swiperIsInit : false,
    swiper : '',
    sortableIsInit : false,
	initialize : function ($) {
		// appelé aussitôt la page chargée
		// console.log("module " + this.NAME);

		_.bindAll(this, "onWindowResize", "initSwiper", "initOrDestroySwiper", "initOrDestroySortable", "equalHeightCustom");

		//Example to call an external function
		//var UTILS = Revolution.get("mde._utils");
        //UTILS.equalheight($('.bk-mosaique .js-equal-height'));

        if(!$('.js-bk-recommendations').length) {
            return;
        }

        $('.js-criteria-header').on('click', function(e){
            e.stopPropagation();
            e.preventDefault();
            var name = $(this).data('target');
            $(this).toggleClass('closed');
            $("[data-name='" + name + "']").slideToggle( "slow" );
        });

        // Reset button
        $('.js-criteria-reset').on('click', function(e){
            e.stopPropagation();
        });

        this.initOrDestroySwiper();


        // Equal height custom
        this.equalHeightCustom();

        // Init tooltips
        this.initTooltips();
	},

	onWindowLoad : function (e) {
        setTimeout(_.bind(function() {
            this.equalHeightCustom();
        },this), 500);
	},
	onWindowScroll : function (e) {
		//
	},
	onWindowResize : function (e) {
        if($('.js-bk-recommendations').length) {
            this.initOrDestroySwiper();
        }
        this.equalHeightCustom();
	},

	initSwiper : function () {
		this.swiper = new Swiper('.js-recommendations-list', {
            slidesPerView: 4,
            slideToClickedSlide: false,
            simulateTouch: false,
            noSwiping: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                1023: {
                  slidesPerView: 2
                },
                767: {
                  slidesPerView: 1
                }
            }
        });
    },

	initOrDestroySwiper : function () {
        if ($(window).width() < 1024) {
            if (!this.swiperIsInit) {
                this.initSwiper();
                this.swiperIsInit = true;

            }
        } else {
            if (this.swiperIsInit) {
                this.swiper.destroy();
                this.swiperIsInit = false;
            }
        }
        $('.js-recommendations-list').attr('data-init-swiper', this.swiperIsInit);
        this.initOrDestroySortable(!this.swiperIsInit);
    },

	initOrDestroySortable : function (init) {
        if(init) {
            if (!this.sortableIsInit) {
                $( ".js-sortable" ).sortable({
                    handle: ".icon-move",
                    revert: true
                });
                this.sortableIsInit = true;
            }
        }
    },

    equalHeightCustom: function() {
        var personHeight = [];
        // $('.js-criterias-list-wrapper .js-criteria-person').height('');
        $('.js-criterias-list-wrapper .js-criteria-person').each(function(index, el){
            personHeight.push($(el).height());
        });
        var headerHeight = [];
        $('.js-criterias-list-wrapper .js-criteria-header').height('');
        $('.js-criterias-list-wrapper .js-criteria-header').each(function(index, el){
            headerHeight.push($(el).height());
        });
        var contentHeight = [];
        $('.js-criterias-list-wrapper .criteria-content li').height('');
        $('.js-criterias-list-wrapper .criteria-content').each(function(index, el){
            var criteriaName = $(el).data('name');
            contentHeight[criteriaName] = [];
            $(el).find('li').each(function(index, el){
                contentHeight[criteriaName].push($(el).height());
            });
        });
        $('.js-recommendations-list .swiper-slide').each(function(index, el){
            $(el).find('.criteria-person').each(function(index, el){
                $(el).height(personHeight[index]);
            })
            $(el).find('.criteria-header').each(function(index, el){
                $(el).height(headerHeight[index]);
            })
            $(el).find('.criteria-content').each(function(index, el){
                var slideCriteriaName = $(el).data('name');
                $(el).find('li').each(function(index, el){
                    $(el).height(contentHeight[slideCriteriaName][index]);
                })
            })
        });
    },


    initTooltips: function() {
        $('.tooltip').each(function(index, el){
            $(el).tooltipster({
                side: ['right', 'bottom', 'top'],
                theme: 'tooltipster-noir',
                content: $(el).data('content'),
                maxWidth: 500
            });
        });
    }
});
