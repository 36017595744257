Revolution.Module.extend({
    NAME: "mde.sliders", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        //_.bindAll(this, "equalheight", "runEqualHeight", "getHeightsForOffsets");

        //events


    },

    onWindowLoad: function (e) {
        
    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {
        
    },

    customfunction: function () {
       
    }

});