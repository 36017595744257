Revolution.Module.extend({
    NAME: "mde.popup-notice", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/popup-notice.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        //_.bindAll(this, "equalheight", "runEqualHeight", "getHeightsForOffsets");

        //events

        $(".js-popup-text").scroll(_.bind(function (e) {
            this.activateCheckbox($(e.currentTarget));
        }, this));

        $(".js-popup-checkbox").change(function(){
            var $btn = $(this).closest('.js-bk-popup').find('.js-popup-btn');
            if ($(this).is(':checked')) {
                $btn.removeClass('disabled');
            } else {
                $btn.addClass('disabled');
            }
        });

        $(".js-open-popup-notice").on('click', _.bind(function(e) {
            e.preventDefault();
            $(e.currentTarget).next('.js-bk-popup').addClass('displayed');
            $('body').css('overflow', 'hidden');
            this.activateCheckbox($(e.currentTarget).next('.js-bk-popup').find(".js-popup-text"));
        }, this));

        // Close popup
        $(".js-popup-cross").on('click', function(){
            $(this).closest('.js-bk-popup').removeClass('displayed');
            $('body').removeAttr('style');
        });
    },

    onWindowLoad: function (e) {

    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {

    },

    activateCheckbox: function (textarea) {
        // + 15 : compensate the margin-bottom of textaera (calcul is alterate when zoom is over 100%)
        if ( (textarea.scrollTop() + textarea.innerHeight() + 15) >= textarea[0].scrollHeight ) {
            textarea.closest('.js-bk-popup').find(".js-popup-checkbox").removeAttr("disabled");
        }
    }

});
