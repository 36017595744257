Revolution.Module.extend({
    NAME : "mde.formulaire", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)
    defaultZone: 5,
    limitZone: 5,
    genderActivate: false,
    initialize : function ($) {

        _.bindAll(this, "initListAutocomplete", "initSelectWithCheckbox", "initCategories", "initBtnNextQuestion", "loadInputCustomSize", "inputCustomSize", "showNextField", "checkQuestionWithSelect", "checkQuestionWithInput", "selectLineContent", "initZones", "addZone", "removeZone", "keepCoverageCountriesSelected", "createHiddenInput", "removeHiddenInput");

        if(!$('.js-bk-formulaire').length) {
            return;
        }

        // List autocomplete
        this.initListAutocomplete();

        // Select (with checkbox)
        this.initSelectWithCheckbox();

        // List categories
        this.initCategories();

        // Init tooltips
        this.initTooltips();

        // Init custom size for inputs
        this.loadInputCustomSize();
        $('.js-input-custom-size input[type="text"], .js-input-custom-size input[type="number"]').on('input', this.inputCustomSize);
        $('.form-field input[type="number"]').on('keydown', this.disableCharENumber);


        // SELECT
        $('.form-field select').on("select2:select", this.checkQuestionWithSelect);

        // INPUT
        $('.js-form-field.field-classic input').on('focusout', this.checkQuestionWithInput);

        // CHECKBOXES
        $('.js-filter-checkbox input[type=checkbox]').change(_.bind(function(e) {
            var currentQuestion = $(e.currentTarget).closest('.js-form-field');
            var nextQuestion = $('.form-field[data-question='+currentQuestion.data('nextQuestion')+']');
            this.showNextField(currentQuestion, nextQuestion);
        }, this));

        // RADIO (GENDER)
        if ($('input[type=radio][name=gender]').length) {
            this.genderActivate = true;
            $('input[type=radio][name=gender]').change(_.bind(function(e) {
                var currentQuestion = $(e.currentTarget).closest('.js-form-field');
                var nextQuestion = $('.form-field[data-question='+currentQuestion.data('nextQuestion')+']');
                this.showNextField(currentQuestion, nextQuestion);
            }, this));
        };

        // Click on a question already answered
        $('.form-field input[type="text"], .form-field input[type="number"]').on('focusin', this.selectLineContent);
        $('.form-field select').on("select2:open", this.selectLineContent);

        // $('body').on('click touchstart', '.form-field.answered span', this.selectLine);
    },

    onWindowLoad : function (e) {
        //
    },
    onWindowScroll : function (e) {
        //
    },
    onWindowResize : function (e) {
        //
    },
    initListAutocomplete : function () {
        // FIXME: setting source to countries list for all autocomplete fields ?
        $( ".js-form-field .field-autocomplete" ).each(_.bind(function(index, el) {
            $(el).autocomplete({
                minLength: 0,
                source: getResidenceCountriesSource(),
                select: _.bind(function(event, ui) {
                    event.stopPropagation();
                    setTimeout(_.bind(function(){
                        $(el).val(ui.item.label);
                        this.loadInputCustomSize();
                        $(el).focusout();
                    }, this), 100);

                    // Deactivate the residence country in the list of coverage countries
                    if ($(event.target).data('role') === "residence-country") {
                        var selectedCountry = ui.item.value;
                        var countryToDisabled = $('.js-form-field.multiple-checkbox[data-role="coverage-country"] option[data-country-id="'+selectedCountry+'"]');
                        this.resetZones();
                        this.initZones(countryToDisabled);
                        $('.hidden-residence-country').val(selectedCountry);
                        this.keepCoverageCountriesSelected();
                    };

                    this.loadInputCustomSize();
                    this.checkQuestionWithSelect(event);
                }, this),
                focus: _.bind(function(event, ui) {
                    $(el).val(ui.item.label);
                    this.loadInputCustomSize();
                    $(el).focusout();
                    return false;
                }, this)
            }).focus(function () {
                $(this).autocomplete("search");
            }).autocomplete( "instance" )._renderItem = function( ul, item ) {
                return $( "<li>" )
                  .append( "<div data-value='"+item.value+"'>" + item.label + "</div>" )
                  .appendTo( ul );
            };
            $(el).autocomplete( "option", "appendTo", $(el).parent());
        }, this));
    },
    initSelectWithCheckbox : function () {
        $('[data-type="multiple-checkbox"] select').each(_.bind(function(index, el){
            $(el).multipleSelect({
                filter: true,
                selectAll: false,
                width: '30%',
                placeholder: $(el).data('placeholder'),
                onOpen: _.bind(function() {
                    $('.default-country').find('input').prop('disabled', true);
                    if (!$('.custom-checkbox').length) {
                        // Add span after input checkbox
                        var newSpan = $( "<span class='label-hidden'></span>" );
                        $('.js-select-checkbox .ms-drop ul li label input').after(newSpan);
                        $('.js-select-checkbox .ms-drop ul li label').addClass('custom-checkbox');
                    }
                    this.selectLineContent($(el).closest('.js-form-field'));
                }, this),
                onClose : _.bind(function() {
                    var e = {currentTarget: $(el)};
                    this.checkQuestionWithSelect(e);
                }, this),
                onClick: _.bind(function(view) {
                    if (view.checked) {
                        this.createHiddenInput(view, false);
                        this.addZone(view);
                    } else {
                        this.removeZone();
                        this.removeHiddenInput(view);
                    }
                }, this)
            });
        }, this));
    },
    initCategories : function () {
        $('.js-category-item .category-icon label').on('click touchstart', _.bind(function(e){
            $(e.currentTarget).closest('.js-category-item').toggleClass('selected');
            var currentQuestion = $(e.currentTarget).closest('.js-form-field');
            var nextQuestion = $('.form-field[data-question='+currentQuestion.data('nextQuestion')+']');
            this.showNextField(currentQuestion, nextQuestion);
        }, this));
    },
    initTooltips : function () {
        $('.tooltip').each(function(index, el){
            $(el).tooltipster({
                side: ['right', 'bottom', 'top'],
                theme: 'tooltipster-noir',
                content: $(el).data('content'),
                maxWidth: 500
            });
        });
    },
    initBtnNextQuestion : function () {
        $('.js-btn-next-question').on('click touchstart', _.bind(function(e){
            e.preventDefault();
            var currentQuestion = $(e.currentTarget).closest('.js-form-field');
            var nextQuestion = $('.form-field[data-question='+currentQuestion.data('nextQuestion')+']');
            this.showNextField(currentQuestion, nextQuestion);
        }, this));
    },
    loadInputCustomSize : function() {
        $('.js-input-custom-size input[type="text"], .js-input-custom-size input[type="number"]').each(function(index, el) {
            if($(el).val() != "") {
                var placeholder = $(el).val();
            } else {
                var placeholder = $(el).attr('placeholder');
            }
            var fontSize = $(el).css('font-size');
            var fontFamily = $(el).css('font-family');
            var padding = $(el).css('padding');
            if($(el).next('.js-get-size-input').length) {
                $(el).next('.js-get-size-input').remove();
            }
            if(placeholder != undefined) {
                $('<span class="js-get-size-input" style="position: absolute; top: 0; left: 0; opacity: 0; white-space: pre-wrap; font-family: '+fontFamily+'; font-size: '+fontSize+';">'+placeholder+'</span>').insertAfter($(el));
            }
            var width = $(el).next('.js-get-size-input').width();
            $(el).width(width);
        });
    },
    inputCustomSize : function(e) {
        var $obj = $(e.currentTarget);

        if($obj.val() != "") {
            var placeholder = $obj.val();
        } else {
            var placeholder = $obj.attr('placeholder');
        }
        var fontSize = $obj.css('font-size');
        var fontFamily = $obj.css('font-family');
        var padding = $obj.css('padding');
        if($obj.next('.js-get-size-input').length) {
            $obj.next('.js-get-size-input').remove();
        }
        if(placeholder != undefined) {
            $('<span class="js-get-size-input" style="position: absolute; top: 0; left: 0; opacity: 0; white-space: pre-wrap; font-family: '+fontFamily+'; font-size: '+fontSize+';">'+placeholder+'</span>').insertAfter($obj);
        }
        var width = $obj.next('.js-get-size-input').width() + 1;
        $obj.width(width);
    },
    disableCharENumber : function(e) {
        // disable character e on input number
        if(e.keyCode == 69) {
            return false;
        }
    },

    showNextField : function(currentQuestion, nextQuestion) {
        currentQuestion.addClass('answered').attr('data-status', 1);

        if (nextQuestion.length) {
            // Display the next question
            nextQuestion.removeClass('out');
            this.loadInputCustomSize();

            if (nextQuestion.hasClass('js-optional')) {
                var currentQuestion = nextQuestion;
                var nextQuestion = $('.form-field[data-question='+currentQuestion.data('nextQuestion')+']');
                this.showNextField(currentQuestion, nextQuestion);
            }
        } else {
            // Display the submit button
            $('input[type="submit"]').attr('disabled', false);
        }
    },
    checkQuestionWithSelect : function (e) {
        console.log('checkQuestionWithSelect');
        var currentQuestion = $(e.currentTarget).closest('.js-form-field');
        var nextQuestion = $('.form-field[data-question='+currentQuestion.data('nextQuestion')+']');

        // If it is the select for the number of children, do nothing
        if (currentQuestion.hasClass('js-check-needed')) {
            return;
        }

        // Handle family members
        if (currentQuestion.hasClass('js-check-family')) {
            var nextNumQuestion = currentQuestion.data('nextQuestion');
            var tab = {
                'user' : [1],
                'user_and_spouse' : [1, 2],
                'user_spouse_and_children' : [1, 2, 3],
                'user_and_children' : [1, 3]
            };
            var fieldPartToDisplay = currentQuestion.find('select.custom-select').val();
            // Iterate over subfields to hide it and empty value if it must not be displayed, or just show it
            nextQuestion.find('[data-field-part]').each(function(){
                var dataFieldPart = $(this).data('field-part');
                if (tab[fieldPartToDisplay].indexOf(dataFieldPart) === -1) {
                    $(this).removeClass('displayed');
                } else {
                    $(this).addClass('displayed');
                }
            });
            nextQuestion.find('select').on('change', _.bind(function (e) {
                nextQuestion.find('input[data-field-part="'+ ($(e.target).data('field-part') + 1) +'"]').remove();
                nextQuestion.find('span[data-field-part="'+ ($(e.target).data('field-part') + 1) +'"]').remove();
                var valueMin = $('.js-data-children').data('min');
                var valueMax = $('.js-data-children').data('max');
                var word = '';
                for(var i = 1; i <= $(e.target).val(); i++) {
                    if (i === 1) {
                        if (parseInt($(e.target).val()) === 1) {
                            word = $('.js-text-is').data('textSingular');
                            $('.js-text-child').html($('.js-text-child').data('textSingular'));
                        } else {
                            word = $('.js-text-is').data('textPlural');
                            $('.js-text-child').html($('.js-text-child').data('textPlural'));
                        }
                    } else if (i === parseInt($(e.target).val())) {
                        word = $('.js-text-and').data('text');
                    } else {
                        word = ",";
                    }
                    $('<span data-field-part="'+($(e.target).data('field-part') + 1)+'">'+word+'</span><input class="input-child" data-field-part="'+($(e.target).data('field-part') + 1)+'" type="number" name="insured_2_4_'+i+'" id="insured_2_4_'+i+'" min="'+valueMin+'" max="'+valueMax+'" data-error-target="children"/>').insertBefore('.form-field[data-question='+nextNumQuestion+'] .js-error-message-wrapper');
                }
                // add 'years old' after all inputs for children
                $('<span data-field-part="'+($(e.target).data('field-part') + 1)+'">'+$('.js-text-years').data('text')+'.</span>').insertBefore('.form-field[data-question='+nextNumQuestion+'] .js-error-message-wrapper');

                nextQuestion.find('[data-field-part="'+ ($(e.target).data('field-part') + 1) +'"]').addClass('displayed');
                $('input[data-field-part="'+($(e.target).data('field-part') + 1)+'"]').on('focusout', this.checkQuestionWithInput);
            }, this));
            if (fieldPartToDisplay === "user_spouse_and_children" || fieldPartToDisplay === "user_and_children" ) {
                var selectChild = nextQuestion.find('select');

                // START MODE1 : Display 3 or 4 children in the select
                selectChild.select2('destroy');
                var numMax = fieldPartToDisplay === "user_spouse_and_children" ? 3 : 4;
                nextQuestion.find('select.custom-select option').each(function(index, el) {
                    if (index > 0) {
                        (index <= numMax) ? $(el).removeAttr('disabled') : $(el).attr('disabled', 'disabled');;
                    }
                });
                selectChild.select2({
                    minimumResultsForSearch: -1,
                    width: 'auto',
                    theme: 'border-bottom',
                    dropdownParent: selectChild.parent()
                });
                // END MODE1 : Display 3 or 4 children in the select

                // Select one child by default
                selectChild.val(1).trigger('change').trigger('select2:select');
            }

            // Show/Hide gender condition
            if (this.genderActivate) {
                if (fieldPartToDisplay === "user" || fieldPartToDisplay === "user_and_children" ) {
                    var inputMyAge = $('input[data-field-part=1]');
                    var minAgeMaternity = $('input[data-field-part=1]').attr('min-maternity');
                    var maxAgeMaternity = $('input[data-field-part=1]').attr('max-maternity');
                    if (minAgeMaternity <= inputMyAge.val() && inputMyAge.val() <= maxAgeMaternity) {
                        $('.js-gender').show();
                    } else {
                        $('.js-gender').hide();
                    }
                }
                if (fieldPartToDisplay === "user_and_spouse" || fieldPartToDisplay === "user_spouse_and_children" ) {
                    $('.js-gender').hide();
                }
            }

            // Disabled submit button if some inputs are empty
            if (parseInt($('[type="submit"]').css('opacity')) === 1 || $('[type="submit"]').hasClass('disabled')) {
                $('[type="submit"]').removeClass('disabled');
                nextQuestion.find('input[type="number"]:visible').each(function(){
                    if ($(this).val() === '') {
                        $('[type="submit"]').addClass('disabled');
                    }
                });
            }
        }

        this.showNextField(currentQuestion, nextQuestion);
    },
    checkQuestionWithInput : function (e) {
        console.log('checkQuestionWithInput');
        var currentQuestion = $(e.currentTarget).closest('.js-form-field');
        var nextQuestion = $('.form-field[data-question='+currentQuestion.data('nextQuestion')+']');
        var allInputsAnswered = true;
        var allSelectsAnswered = true;
        var allRadioAnswered = true;

        if (this.genderActivate) {
            if ($(e.currentTarget)[0].tagName === "INPUT" && $(e.currentTarget).closest('.js-form-field').find('.js-gender').length) {
                var whoIsCovered = $('.js-custom-select-cover').val();
                var inputMyAge = $('input[data-field-part=1]');
                var minAgeMaternity = $('input[data-field-part=1]').attr('min-maternity');
                var maxAgeMaternity = $('input[data-field-part=1]').attr('max-maternity');
                switch (whoIsCovered) {
                    case 'user':
                    case 'user_and_children':
                    if (minAgeMaternity <= inputMyAge.val() && inputMyAge.val() <= maxAgeMaternity) {
                        inputMyAge.closest('.js-form-field').find('.js-gender').show();
                    } else {
                        inputMyAge.closest('.js-form-field').find('.js-gender').hide();
                    }
                    break;

                    default:
                    break;
                }
            }
        }

        if (currentQuestion.hasClass('js-check-needed')) {
            currentQuestion.find('input[type="number"]:visible').each(function(index, el){
                var value = parseInt($(el).val());
                var valueMin = parseInt($(el).attr('min'));
                var valueMax = parseInt($(el).attr('max'));
                var inputName = $(el).data('error-target');

                $('.js-error-message[data-error-name="'+inputName+'"').hide();

                if (!value) {
                    allInputsAnswered = false;
                    return;
                } else {
                    if (value < valueMin || value > valueMax) {
                        $('.js-error-message[data-error-name="'+inputName+'"').show();
                        if (inputName === "children") {
                            return false;
                        }
                    }
                }
            });
            currentQuestion.find('select.custom-select:visible').each(function(index, el){
                if (!$(el).val()) {
                    allSelectsAnswered = false;
                    return;
                }
            });
            if (this.genderActivate) {
                if (currentQuestion.find('input[name="gender"]:visible').length) {
                    allRadioAnswered = false;
                    if (currentQuestion.find('input[name="gender"]:visible:checked').length){
                        allRadioAnswered = true;
                    }
                }
            }
            if ($('.js-error-message').is(':visible')) {
                allInputsAnswered = false;
            }
        }

        if (allInputsAnswered && allSelectsAnswered && allRadioAnswered) {
            this.showNextField(currentQuestion, nextQuestion);

            // Enable submit button if it is disabled
            if ($('[type="submit"]').hasClass('disabled')) {
                $('[type="submit"]').removeClass('disabled');
            }
        } else {
            $('[type="submit"]').addClass('disabled');
        }

    },



    selectLineContent: function(e) {
        if((e.type == "focusin") || (e.type == "select2:open")) {
            var $obj = $(e.currentTarget);
            $line = $obj.closest('.form-field');
        } else { // si select chebkox
            $line = e;
        }

        $('.form-field').not('.out').addClass('answered');
        $line.removeClass('out answered');
        this.loadInputCustomSize();

    },

    resetZones : function () {
        // Deselect all option of the select tag
        $('.js-select-checkbox option').prop('selected', false).prop('disabled', false);
        // Refresh the list
        $('[data-type="multiple-checkbox"] select').multipleSelect('refresh');
    },
    initZones : function (countryToDisabled) {
        var view = [];
        view.value = countryToDisabled.data('zone');
        view.label = countryToDisabled.data('name');
        // Check the selected country
        $('.js-form-field.multiple-checkbox[data-role="coverage-country"] option').prop("selected", false).removeClass('default-country');
        countryToDisabled.prop("selected", true).addClass('default-country');
        $('.ms-drop ul li label:contains("'+view.label+'")').click();
        // Refresh the list
        $('[data-type="multiple-checkbox"] select').multipleSelect('refresh');
        // Update zone number
        this.defaultZone = view.value;
        this.limitZone = view.value;
        $('.hidden-coverage-zone').val(this.limitZone);
        this.createHiddenInput(view, true);
    },
    addZone : function (view) {
        var value = parseInt($($('.js-select-checkbox option[value='+view.value+']')[0]).data('zone'));
        if (parseInt(value) < parseInt(this.limitZone)) {
            this.limitZone = value;
            $('.hidden-coverage-zone').val(this.limitZone);
        }
    },
    removeZone : function () {
        if ($('.js-select-checkbox li.selected').length > 0) {
            var limitZoneTemp = this.defaultZone;
            var listItemsChecked = $('.js-select-checkbox li.selected');
            for (i = 0; i < listItemsChecked.length; i++) {
                var value = parseInt($($('.js-select-checkbox option[value='+$(listItemsChecked[i]).find('input').val()+']')[0]).data('zone'));
                if (value < limitZoneTemp) {
                    limitZoneTemp = value;
                }
            }

            if (parseInt(limitZoneTemp) > parseInt(this.limitZone)) {
                this.limitZone = limitZoneTemp
            }

            $('.hidden-coverage-zone').val(this.limitZone);
        } else {
            this.limitZone = this.defaultZone;
            $('.hidden-coverage-zone').val(this.defaultZone);
        }
    },
    keepCoverageCountriesSelected: function() {
        if ($('.hidden-coverage-country').length) {
            var lstCoverageCountries = [];
            $('.hidden-coverage-country').each(_.bind(function(index, el){
                if (el.value != $('.hidden-residence-country').val()) {
                    lstCoverageCountries.push(el.value);
                }
            }, this));
            lstCoverageCountries.push($('.hidden-residence-country').val());
            $('[data-type="multiple-checkbox"] select').multipleSelect('setSelects', lstCoverageCountries);
        }
    },
    updateLabelOfList : function () {
        if ($('.js-select-checkbox li.selected').length > 3) {
            $('.js-select-checkbox button span').html($('.js-select-checkbox li.selected').length+' of '+($('.js-select-checkbox li').length - 1)+' selected');
        } else {
            var listOfCountries = '';
            for (var i = 0; i < $('[data-type="multiple-checkbox"] select').multipleSelect('getSelects', "text").length; i++) {
                var value = $('[data-type="multiple-checkbox"] select').multipleSelect('getSelects', "text")[i];
                listOfCountries += (listOfCountries == '') ? value : ', '+value;
            }
            $('.js-select-checkbox button span').html(listOfCountries);
        }
    },
    createHiddenInput : function (view, residence) {
        var countryId = view.value;
        if (residence) {
            $('.hidden-residence-country').val(countryId);
        } else {
            var fieldsCount = $('[name^="country-coverage"]').length;
            $('.js-select-checkbox').closest('.form-field').append(
                '<input type="hidden" name="country-coverage-'+fieldsCount+'" value="'+countryId+'" class="hidden-coverage-country">'
            );
        }
    },
    removeHiddenInput : function (view) {
        var countryId = parseInt($($('.js-select-checkbox option[value='+view.value+']')[0]).data('country-id'));
        $('.hidden-coverage-country[value="'+countryId+'"]').remove();
    },

    // selectLine: function(e) {
    //     var $obj = $(e.currentTarget);
    //     $line = $obj.closest('.form-field');

    //     if(!$line.hasClass('.answered')) {
    //         if($line.hasClass('list-autocomplete')) {
    //             $line.find('.field-autocomplete').trigger('focus');
    //         }
    //         else if($line.hasClass('multiple-checkbox')) {
    //             $line.find('.select-checkbox button').click();
    //         }
    //         else if($line.find('input').length) {
    //             $line.find('input.displayed').first().focus();
    //         }
    //         else if($line.hasClass('list-coverage')) {
    //             $('.form-field').not('.out').addClass('answered');
    //             $line.removeClass('out answered');
    //         } else {
    //             $('.form-field').not('.out').addClass('answered');
    //             $line.removeClass('out answered');
    //             $line.find('select').select2('open').focus();
    //         }
    //         this.loadInputCustomSize();
    //     }
    // },
});
