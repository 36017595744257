Revolution.Module.extend({
	NAME : "mde.docs-list", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/docs-list.js)
    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        //_.bindAll(this, "equalheight", "runEqualHeight", "getHeightsForOffsets");

        //events

        if ($('.js-btn-docs-list').length) {
            $('.js-btn-docs-list').on('click', function(e){
                $('.js-bk-docs-list').toggleClass('opened');
            });

            $('.js-btn-close-list').on('click', function(e){
                $('.js-bk-docs-list').removeClass('opened');
            });
        }
    },

    onWindowLoad: function (e) {

    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {

    },

    customfunction: function () {

    }
});
